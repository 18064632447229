<template>
    <div>
        <PageHeader :title="'Visualizar Boletas'" :items="breadcrumb" />

        <b-card class="mb-3 mt-3">
            <b-container fluid class="ml-3 mt-2">
                <b-row align-v="center" class="my-3">
                    <b-col md="2">
                        <b-form-group label="Mês de Referência" label-for="filtroMesRef">
                            <b-form-select id="filtroMesRef" v-model="filtroMesRef" :options="meses"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Ano de Referência" label-for="filtroAnoRef">
                            <b-form-input id="filtroAnoRef" type="number" v-model="filtroAnoRef"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Tipo Operação" label-for="tipoOperacao" class="form">
                            <b-form-select id="operacoes" :options="operacoes" v-model="venda" @change="muda" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Sigla Perfil ContraParte" label-for="siglaPerfil" class="form">
                            <b-form-input id="siglaPerfil" type="text" v-model="sigla" @keyup.enter="fetchData"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Fonte" label-for="fonte" class="form">
                            <b-form-select id="fonte" :options="fontes" v-model="fonte" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Submercado" label-for="submercado" class="form">
                            <b-form-select id="submercado" :options="submercados" v-model="submercado" />
                        </b-form-group>
                    </b-col>

                </b-row>

                <b-row align-v="center" class="my-3">
                    <b-col md="2" class="d-flex justify-content">
                        <b-button @click="fetchData" class="ml-2 b" id="md">Listar Boletas</b-button>
                        <b-spinner v-if="loading" text-align="center" variant="success" label="Spinning"
                            class="ml-2 mt-3" />
                    </b-col>
                </b-row>
            </b-container>
        </b-card>

        <BoletaTable :loading="loading" :dados="dadosFiltrados"></BoletaTable>
    </div>
</template>

<style scoped>
#md {
    width: 8vw;
}
</style>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import PageHeader from '@/components/page-header';
import { findByMesRef } from '@/features/validacoes/ParcelaBoletaValidation.service';
import meses from '@/utils/meses';
import BoletaTable from './BoletaTable';

Vue.use(VueMask);

export default {
    data() {
        return {
            mesRefInput: '',
            venda: true,
            dados: null,
            dadosVenda: null,
            dadosCompra: null,
            dadosFiltrados: null,
            meses: meses(),
            mesRef: '',
            filtroMesRef: new Date().getMonth() + 1,
            filtroAnoRef: new Date().getFullYear(),
            loading: false,
            breadcrumb: [
                {
                    text: 'Backoffice',
                    href: '#'
                },
                {
                    text: 'Registros',
                    href: '#'
                },
                {
                    text: 'Boletas',
                    active: true
                }
            ],
            operacoes: [
                { value: true, text: 'Vendas' },
                { value: false, text: 'Compras' }],
            fontes: [
                {value: null, text: 'Todos'},
                { value: 'CO', text: 'CO' },
                { value: 'I0', text: 'I0' },
                { value: 'I5', text: 'I5' },
                { value: 'I1', text: 'I1' },
                { value: 'INE', text: 'INE' },
                { value: 'CQ5', text: 'CQ5' }],
            fonte: null,
            submercados: [
                {value: null, text: 'Todos'},
                { value: 'SE', text: 'SE' },
                { value: 'S', text: 'S' },
                { value: 'N', text: 'N' },
                { value: 'NE', text: 'NE' }],
            submercado: null,
            sigla: null
        }
    },
    components: {
        PageHeader, BoletaTable
    },
    created() {
        this.atualizarDataRef();
    },
    methods: {
        muda(){
            if(this.venda){
                this.dadosFiltrados = this.dadosVenda;
            }else{
                this.dadosFiltrados = this.dadosCompra;
            }
        },
        getFiltro(){
            return {
                siglaPerfilCP: this.sigla,
                cliqccee: null,
                fonte: this.fonte,
                submercado: this.submercado,
                vrsim: true,
                vrnao: true,
                arsim: true,
                arnao: true,
                vasim: true,
                vanao: true,
                finsim: true,
                finnao: true,
                matchingsim: true,
                matchingnao: true,
                netsim: true,
                netnao: true
            };
        },
        filtro() {
            this.dadosVenda = this.dados.boletas.filter(item => item.tipoBoleta === 'V');
            this.dadosCompra = this.dados.boletas.filter(item => item.tipoBoleta === 'C');
            this.muda();
        },
        atualizarDataRef() {
            if (this.filtroMesRef && this.filtroAnoRef) {
                const mesIndexZeroBase = this.filtroMesRef - 1;
                this.mesRefInput = `${this.filtroAnoRef}-${String(
                    mesIndexZeroBase + 1
                ).padStart(2, '0')}-01`;
            }
        },
        fetchData() {
            this.loading = true;

            findByMesRef(this.mesRefInput, this.getFiltro())
                .then((response) => {
                    if (response.status && response.content) {
                        this.dados = response.content;
                        this.filtro();
                    } else {
                        console.error(
                            'Resposta do servidor não está no formato esperado:',
                            response
                        );
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>
.b {
    margin-top: 10px;
}
</style>