<template>
    <div v-if="dados && !loading">
        <b-table bordered outlined small :fields="fields" :items="dados" responsive class="custom-table-2"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">

            <template #cell(sys.inicio)="data">
                <p class="data">{{ data.value }}</p>
            </template>

            <template #cell(sys.fim)="data">
                <p class="data">{{ data.value }}</p>
            </template>

            <template #cell(sys.volume)="data">
                <p>{{ formatNumber(data.value, 6) }}</p>
            </template>

            <template #cell(sys.preco)="data">
                <p>{{ formatNumber(data.value, 3) }}</p>
            </template>
            
            <template #cell(sys.siglaPerfilParte)="data">
                <p class="perfil">{{ data.value }}</p>
            </template>

            <template #cell(sys.siglaPerfilContraParte)="data">
                <p class="perfil">{{ data.value }}</p>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fields: [{ key: 'codBoleta', label: 'Boleta', headerTitle: 'Código Boleta', sortable: true },
            { key: 'tipoBoleta', label: 'OP', headerTitle: 'Operação' },
            { key: 'sys.tipoContrato', label: 'PC', headerTitle: 'Período Contrato' },
            { key: 'sys.tipoEnergia', label: 'Fonte' },
            { key: 'sys.codigoPerfilParte', label: 'CodP', headerTitle: 'Código Perfil Parte' },
            { key: 'sys.siglaPerfilParte', label: 'SiglaP', headerTitle: 'Sigla Perfil Parte' },
            { key: 'sys.codigoPerfilContraParte', label: 'CodCP', headerTitle: 'Código Perfil Contra Parte' },
            { key: 'sys.siglaPerfilContraParte', label: 'SiglaCP', headerTitle: 'Sigla Perfil Contra Parte', sortable: true },
            {
                key: 'sys.nomeFantasia',
                label: 'Nome',
                headerTitle: 'Nome Fantasia'
            },
            { key: 'sys.tipoEnergia', label: 'Tipo Energia' },
            { key: 'sys.submercado', label: 'Submercado' },
            { key: 'sys.inicio', label: 'Data Ini' },
            { key: 'sys.fim', label: 'Data Fim' },
            { key: 'sys.volume', label: 'Volume' },
            { key: 'sys.preco', label: 'Preço' }],
            sortBy: 'codBoleta',
            sortDesc: false
        }
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        dados: {
            type: Array,
            default: null
        }
    },
    methods: {
        formatNumber(number, decimals) {
            // Verifica se o número é zero
            if (number == 0) {
                return 0;
            } else {
                // Caso contrário, formata o número com o número especificado de casas decimais
                return number.toLocaleString('pt-BR', { minimumFractionDigits: decimals });
            }
        }
    }
};
</script>

<style scoped>
.data {
    width: 90px;
}

.custom-table-2 {
    font-size: 14px;
}

.perfil {
  width: 130px;
}
</style>