import dayjs from 'dayjs';
import Config from '@/config/config';
import { getAuth } from '../auth/auth.helpers';
import { Base64 } from 'js-base64';

import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
Vue.use(VueToast);

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

//import jsonBoletas from '@/mock/mockBoletaValidation.json';

const CONFIG = new Config();

export const findByMesRef = async (mesRef, filtro) => {
  var axios = require('axios');
  var data = filtro;

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/boletas/findByMesRef`,
    headers: {
      Bearer: auth.token
    },
    params: {
      mesRef: mesRef
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      if (response.data.status) {
        Vue.$toast.success(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      } else {
        Vue.$toast.error(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      }


      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      Vue.$toast.error("Erro", {
        position: 'top-right',
        duration: 5000
      });

      return null;
    });
};

export const tryMatching = async (mesRef) => {
  var axios = require('axios');
  var data = { mesRef: mesRef };

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/matching/tryMesRef`,
    headers: {
      Bearer: auth.token
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      if (response.data.status) {
        Vue.$toast.success(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      } else {
        Vue.$toast.error(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      }


      return response;
    })
    .catch(function (error) {
      console.log(error);
      Vue.$toast.error("Erro", {
        position: 'top-right',
        duration: 5000
      });

      return null;
    });
};

export const salvaEstrutura = async (boleta) => {
  var axios = require('axios');
  var data = [];
  data.push(boleta);

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/boletas/salvaEstrutura`,
    headers: {
      Bearer: auth.token
    },
    data: data
  };

  console.log(config);

  return axios(config)
    .then(function (response) {
      if (response.data.status) {
        Vue.$toast.success(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      } else {
        Vue.$toast.error(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      }


      return response;
    })
    .catch(function (error) {
      console.log(error);
      Vue.$toast.error("Erro", {
        position: 'top-right',
        duration: 5000
      });

      return null;
    });
};

export const criaSimplificado = async (boletas) => {
  var axios = require('axios');
  var data = [];

  boletas.forEach(element => {
    data.push({"id":element.id});
  });

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/xml/criarSimplificado`,
    headers: {
      Bearer: auth.token
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      if (response.data.status) {
        const decoded = Base64.atob(response.data.content.arquivo);

        return require('downloadjs')(
          decoded,
          response.data.content.nomeArquivo,
          "application/xml"
        );

      } else {
        Vue.$toast.error(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      }


      return response;
    })
    .catch(function (error) {
      console.log(error);
      Vue.$toast.error("Erro", {
        position: 'top-right',
        duration: 5000
      });

      return null;
    });
};

export const atualizaSimplificado = async (boletas) => {
  var axios = require('axios');
  var data = [];

  boletas.forEach(element => {
    data.push({"id":element.id});
  });

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/xml/atualizarSimplificado`,
    headers: {
      Bearer: auth.token
    },
    data: data
  };

  //console.log(config);

  return axios(config)
    .then(function (response) {
      if (response.data.status) {
        const decoded = Base64.atob(response.data.content.arquivo);

        return require('downloadjs')(
          decoded,
          response.data.content.nomeArquivo,
          "application/xml"
        );

      } else {
        Vue.$toast.error(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      }


      return response;
    })
    .catch(function (error) {
      console.log(error);
      Vue.$toast.error("Erro", {
        position: 'top-right',
        duration: 5000
      });

      return null;
    });
};

export const validarSimplificadoMZerado = async (boletas) => {
  var axios = require('axios');
  var data = [];

  boletas.forEach(element => {
    data.push({"id":element.id});
  });
 
  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/xml/validarSimplificadoMZerado`,
    headers: {
      Bearer: auth.token
    },
    data: data
  };

  //console.log(config);

  return axios(config)
    .then(function (response) {
      if (response.data.status) {
        const decoded = Base64.atob(response.data.content.arquivo);

        return require('downloadjs')(
          decoded,
          response.data.content.nomeArquivo,
          "application/xml"
        );

      } else {
        Vue.$toast.error(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      }


      return response;
    })
    .catch(function (error) {
      console.log(error);
      Vue.$toast.error("Erro", {
        position: 'top-right',
        duration: 5000
      });

      return null;
    });
};

export const validarSimplificadoAjusteM = async (boletas) => {
  var axios = require('axios');
  var data = [];

  boletas.forEach(element => {
    data.push({"id":element.id});
  });
 
  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/xml/validarSimplificadoAjusteM`,
    headers: {
      Bearer: auth.token
    },
    data: data
  };

  //console.log(config);

  return axios(config)
    .then(function (response) {
      if (response.data.status) {
        const decoded = Base64.atob(response.data.content.arquivo);

        return require('downloadjs')(
          decoded,
          response.data.content.nomeArquivo,
          "application/xml"
        );

      } else {
        Vue.$toast.error(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      }


      return response;
    })
    .catch(function (error) {
      console.log(error);
      Vue.$toast.error("Erro", {
        position: 'top-right',
        duration: 5000
      });

      return null;
    });
};

export const desfazerMatching = async (boletas) => {
  var axios = require('axios');
  var data = [];

  boletas.forEach(element => {
    data.push({"id":element.id});
  });
 
  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/matching/desfazerMatching`,
    headers: {
      Bearer: auth.token
    },
    data: data
  };

  //console.log(config);

  return axios(config)
  .then(function (response) {
    if (response.data.status) {
      Vue.$toast.success(response.data.msg, {
        position: 'top-right',
        duration: 5000
      });
    } else {
      Vue.$toast.error(response.data.msg, {
        position: 'top-right',
        duration: 5000
      });
    }


    return response;
  })
  .catch(function (error) {
    console.log(error);
    Vue.$toast.error("Erro", {
      position: 'top-right',
      duration: 5000
    });

    return null;
  });
};